//==========================================================
//	メディアクエリ
//==========================================================
@mixin mq($breakpoint: sp) {
	@if $breakpoint == sp {
		@media screen and (max-width: 959px) {
			@content;
		}
	} @else if $breakpoint == pc {
		@media screen and (min-width: 1311px) {
			@content;
		}
	} @else if $breakpoint == slaptop {
		@media screen and (min-width:960px) and (max-width:1200px) {
			@content;
		}
	} @else if $breakpoint == tab {
		@media screen and (min-width:581) and (max-width:959px) {
			@content;
		}
	} @else if $breakpoint == mobile {
		@media screen and (max-width:959px) {
			@content;
		}
	} @else if $breakpoint == small {
		@media screen and (max-width:600px) {
			@content;
		}
	} @else if $breakpoint == medium {
		@media screen and (min-width:600px) and (max-width:969px) {
			@content;
		}
	}
	// } @else if $breakpoint == tab_laptop {
	// 	@media screen and (min-width:768px) and (max-width:1200px) {
	// 		@content;
	// 	}
	// } @else if $breakpoint == laptop {
	// 	@media screen and (min-width:1200px) and (max-width:1439px) {
	// 		@content;
	// 	}
	// } @else if $breakpoint == other {
	// 	@media screen and (min-width: 1440px) {
	// 		@content;
	// 	}
	// } @else if $breakpoint == max {
	// 	@media screen and (min-width: 1800px) {
	// 		@content;
	// 	}
	// }
	// } @else if $breakpoint == xlarge {
	// 	@media screen and (max-width: 1280px) {
	// 		@content;
	// 	}
	// }
}