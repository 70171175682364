/*
==========================================================
	common & reset
==========================================================
*/
*,*::before,*::after {
	box-sizing: border-box;
}
*::before,*::after {
	display: block;
}
html {
	width: 100%;
	height: 100%;
	font-size: 16px;
}
body {
	margin: 0;
	font-size: 16px;
	color: $black;
	font-family: "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", 'Yu Gothic', 'Helvetica Neue', 'Segoe UI', 'Hiragino Kaku Gothic ProN', sans-serif,"游明朝", YuMincho;
	font-weight: normal;
	word-break: break-all;
	line-height: 1.8;
	-webkit-text-size-adjust: 100%;
}
figure {
}
a {
	transition: all .2s;
	text-decoration: none;
	color: $black;
	cursor: pointer;
	display: block;
}
ul,dl {
	list-style: none;
	padding: 0;
	margin: 0;
}
dt,dd,th,td,ol {
}
input,textarea,select {
	appearance: none;
	line-height: 1.6;
}
input,textarea {
	display: inline-block;
	border: 1px solid;
	background: none;
	&::placeholder {
		font-size: .9rem;
	}
}
input,select,button {
	cursor: pointer;
}
button {
	border: 0;
	outline: none;
	appearance: none;
	cursor: pointer;
	background: none;
}
hr {
	border-color: #333;
	border-width: 1px 0 0 0;
}
img {
	display: block;
	max-width: 100%;
	height: auto;
}
address {
	font-style: normal;
}
// font-size reset
h1,h2,h3,h4,h5,p,ul,ol, {
	font-size: 1rem;
}
iframe {
	display: block;
	margin: 4rem auto;
}
.body-wrap {
	margin: auto;
	padding: 1.25em;
}
.dn {
	display: none;
}
.vh {
	visibility: hidden;
}
.mb0 {
	margin-bottom: 0 !important;
}
.relative {
	position: relative;
}

/*
==========================================================
	common - font
==========================================================
*/

// font-family
.mincho {
	font-family: "游明朝", YuMincho, sans-serif;
}

// font-size
.xsmall {
	font-size: .75em;
}
.small {
	font-size: .9rem;
}
.normal {
	font-size: 1rem;
}
.medium {
	font-size: 1.2rem;
}
.large {
	font-size: 1.8rem;
}
.xlarge {
	font-size: 2.4rem;
}
// 共通見出し
.heading {
	.heading-inner {
		display: block;
		width: 1251px;
		margin: 0 auto;
	}
	&::after {
		content: "";
		height: 4px;
		background: #333;
		display: block;
		margin-top: 1rem;
		background: url(../images/heading-line.png);
	}
}
.bold {
	font-weight: bold;
}
.center {
	text-align: center !important;
}
.m0 {
	margin: 0;
}

/*
==========================================================
	common - color
==========================================================
*/
.white {
	color: #fff;
}
.black {
	color: $black;
}
.red {
	color: $red;
}
.orange {
	color: $orange;
}
.green {
	color: $green;
}
.blue {
	color: $blue;
}
.black_n {
	color: $black;
}
.yellow {
	color: $yellow;
}

//==========================================================
//	common - background color
//==========================================================
.bg_red {
	background-color: $red;
}
.bg_green {
	background-color: $green;
}
.bg_blue {
	background-color: $blue;
}
.bg_yellow {
	background-color: $yellow;
}
.bg_gray_r {
	background-color: $gray_r;
}
.bg_gray_n {
	background-color: $gray_n;
}
.bg_orange {
	background-color: $orange;
}
.bg_yellow_sr {
	background-color: $yellow_sr;
}
.bg_facebook {
	background-color: $facebook;
}
.bg_twitter {
	background-color: $twitter;
}

.wrapper {
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
}
.wrap {
	width: 1311px;
}
.p-wrap {
	background-color: #fff;
}
.link-inline {
	display: inline;
	text-decoration: underline;
}
.list {
	list-style: disc;
	padding-left: 1.25rem;
	margin: 1rem auto;
}
.link {
	color: $blue;
}
.inline {
	display: inline;
	text-decoration: underline;
}
/*
==========================================================
	パンくず
==========================================================
*/
.breadcrumb {
	margin: 2rem auto;
	span {
		display: inline-block;
		a {
			color: $red;
		}
		.home {
			font-size: 1.1rem;
		}
	}
	.b-arrow {
		background: url(../images/parts/arrow2-right-b.svg);
		width: 15px;
		height: 13px;
		background-size: cover;
		margin-left: 1rem;
		margin-right: 1rem;
		position: relative;
		top: 1px;
		left: 1px;
	}
}

.title-red {
	font-size: 2.5rem;
	color: $red;
}

// /*
// ==========================================================
// 	テーブルプラグイン 調整
// ==========================================================
// */
// table.dataTable tbody tr {
// 	background: none !important;
// }

/*
==========================================================
	メディアクエリ
==========================================================
*/
@include mq() {
	html,body {
		font-size: 14px;
	}
	iframe {
		margin: 2rem auto;
	}
	.wrapper {
		width: 100%;
		padding: 1rem;
	}
}

@include mq {
}