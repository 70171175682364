.flex-bt {
	display: flex;
	justify-content: space-between;
	.item {
		flex-basis: 48%;
	}
}

@include mq {
	.flex-bt {
		display: block;
		.item {
			margin-bottom: 1rem;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}