// color
$black: #141414;
$black_d: #1A1A1A;
$black_footer: #141414;

$brown: #885311;
$brown_r: #ae8958;

$bg_w: #fffdf1;

$blue: #004A9B;

$bronze: #E09742;

$pink: #F9D3D3;

$orange: #ff5200;

$yellow: #ffc622;
$yellow_r: #FFFFC3;
$yellow_sr: #fdffd7;
$yellow_rank: #FFBB22;

$red: #CC0000;

$green: #19d8b2;
$green_r: #1aeb9b;

$gray: #afafaf;
$gray_r: #F4F6FA;
$gray_n: #E4EAF5;
$gray_nd: #d3dbef;
$gray_d: #6F6F6F;
$gray_rank: #CCD9DB;

$header-navitem-border: #BFBFBF;

$facebook: #365899;
$twitter: #55acee;

// font-family
$mincho: "游明朝", YuMincho , serif;