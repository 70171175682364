@charset "UTF-8";
/*
==========================================================
	common & reset
==========================================================
*/
*, *::before, *::after {
  box-sizing: border-box; }

*::before, *::after {
  display: block; }

html {
  width: 100%;
  height: 100%;
  font-size: 16px; }

body {
  margin: 0;
  font-size: 16px;
  color: #141414;
  font-family: "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", 'Yu Gothic', 'Helvetica Neue', 'Segoe UI', 'Hiragino Kaku Gothic ProN', sans-serif,"游明朝", YuMincho;
  font-weight: normal;
  word-break: break-all;
  line-height: 1.8;
  -webkit-text-size-adjust: 100%; }

a {
  transition: all .2s;
  text-decoration: none;
  color: #141414;
  cursor: pointer;
  display: block; }

ul, dl {
  list-style: none;
  padding: 0;
  margin: 0; }

input, textarea, select {
  appearance: none;
  line-height: 1.6; }

input, textarea {
  display: inline-block;
  border: 1px solid;
  background: none; }
  input::placeholder, textarea::placeholder {
    font-size: .9rem; }

input, select, button {
  cursor: pointer; }

button {
  border: 0;
  outline: none;
  appearance: none;
  cursor: pointer;
  background: none; }

hr {
  border-color: #333;
  border-width: 1px 0 0 0; }

img {
  display: block;
  max-width: 100%;
  height: auto; }

address {
  font-style: normal; }

h1, h2, h3, h4, h5, p, ul, ol {
  font-size: 1rem; }

iframe {
  display: block;
  margin: 4rem auto; }

.body-wrap {
  margin: auto;
  padding: 1.25em; }

.dn {
  display: none; }

.vh {
  visibility: hidden; }

.mb0 {
  margin-bottom: 0 !important; }

.relative {
  position: relative; }

/*
==========================================================
	common - font
==========================================================
*/
.mincho {
  font-family: "游明朝", YuMincho, sans-serif; }

.xsmall {
  font-size: .75em; }

.small {
  font-size: .9rem; }

.normal {
  font-size: 1rem; }

.medium {
  font-size: 1.2rem; }

.large {
  font-size: 1.8rem; }

.xlarge {
  font-size: 2.4rem; }

.heading .heading-inner {
  display: block;
  width: 1251px;
  margin: 0 auto; }

.heading::after {
  content: "";
  height: 4px;
  background: #333;
  display: block;
  margin-top: 1rem;
  background: url(../images/heading-line.png); }

.bold {
  font-weight: bold; }

.center {
  text-align: center !important; }

.m0 {
  margin: 0; }

/*
==========================================================
	common - color
==========================================================
*/
.white {
  color: #fff; }

.black {
  color: #141414; }

.red {
  color: #CC0000; }

.orange {
  color: #ff5200; }

.green {
  color: #19d8b2; }

.blue {
  color: #004A9B; }

.black_n {
  color: #141414; }

.yellow {
  color: #ffc622; }

.bg_red {
  background-color: #CC0000; }

.bg_green {
  background-color: #19d8b2; }

.bg_blue {
  background-color: #004A9B; }

.bg_yellow {
  background-color: #ffc622; }

.bg_gray_r {
  background-color: #F4F6FA; }

.bg_gray_n {
  background-color: #E4EAF5; }

.bg_orange {
  background-color: #ff5200; }

.bg_yellow_sr {
  background-color: #fdffd7; }

.bg_facebook {
  background-color: #365899; }

.bg_twitter {
  background-color: #55acee; }

.wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto; }

.wrap {
  width: 1311px; }

.p-wrap {
  background-color: #fff; }

.link-inline {
  display: inline;
  text-decoration: underline; }

.list {
  list-style: disc;
  padding-left: 1.25rem;
  margin: 1rem auto; }

.link {
  color: #004A9B; }

.inline {
  display: inline;
  text-decoration: underline; }

/*
==========================================================
	パンくず
==========================================================
*/
.breadcrumb {
  margin: 2rem auto; }
  .breadcrumb span {
    display: inline-block; }
    .breadcrumb span a {
      color: #CC0000; }
    .breadcrumb span .home {
      font-size: 1.1rem; }
  .breadcrumb .b-arrow {
    background: url(../images/parts/arrow2-right-b.svg);
    width: 15px;
    height: 13px;
    background-size: cover;
    margin-left: 1rem;
    margin-right: 1rem;
    position: relative;
    top: 1px;
    left: 1px; }

.title-red {
  font-size: 2.5rem;
  color: #CC0000; }

/*
==========================================================
	メディアクエリ
==========================================================
*/
@media screen and (max-width: 959px) {
  html, body {
    font-size: 14px; }
  iframe {
    margin: 2rem auto; }
  .wrapper {
    width: 100%;
    padding: 1rem; } }

.centering, .centering_fix {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center; }
  .centeringY {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  .centeringX {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
  .centering_fix {
    position: fixed; }

.centering_fix {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.reverse {
  display: flex;
  flex-direction: row-reverse; }

.common-footer1 {
  margin-top: 4rem;
  background: #CC0000;
  position: relative;
  overflow: hidden; }
  .common-footer1::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.03);
    width: 745px;
    height: 700px;
    background: url(../images/footer/icon-lotas.png); }

.cmp-recr {
  padding: 7rem 0;
  position: relative;
  z-index: 3; }
  .cmp-recr .img {
    margin: 0; }
  .cmp-recr h2 {
    background-color: #fff;
    margin: 0;
    padding: 3rem 1rem;
    font-size: 1.15rem;
    text-align: center;
    line-height: 1.4;
    font-weight: normal; }
  .cmp-recr .item-l .title::after {
    content: "COMPANY";
    font-size: .7em;
    color: #CC0000; }
  .cmp-recr .item-r .title::after {
    content: "RECRUIT";
    font-size: .7em;
    color: #CC0000; }

.common-footer2 {
  background-color: #F9D3D3; }

.footer-banner {
  padding: 2rem 0rem; }

.common-footer3 {
  background-color: #141414;
  color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%; }

#menu-footer-nav3 li {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 1.5rem; }
  #menu-footer-nav3 li:last-child {
    margin-right: 0; }

.footer-btm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #707070;
  padding: 2rem 0 1rem; }

.copyright {
  margin: 0; }

.main-footer {
  padding-top: 3rem; }
  .main-footer .flex-cnt {
    display: flex;
    margin-bottom: 1rem; }
    .main-footer .flex-cnt p {
      margin: 0; }
    .main-footer .flex-cnt .flex-item {
      margin-right: 5%; }
      .main-footer .flex-cnt .flex-item:last-child {
        margin-right: 0; }
    .main-footer .flex-cnt .item1 {
      width: 15%; }
    .main-footer .flex-cnt .item2 {
      width: 30%; }
    .main-footer .flex-cnt .item3 {
      width: 45%; }

.footer-main-nav > .menu-item {
  margin-bottom: 1rem; }

.footer-main-nav .menu-item a {
  color: #fff; }

.footer-main-nav .menu-item .sub-menu {
  padding-left: 2rem;
  list-style: circle;
  margin: .5rem auto; }
  .footer-main-nav .menu-item .sub-menu .menu-item a {
    color: #afafaf; }

.page-top {
  background-color: #CC0000;
  margin: 0;
  text-align: center; }
  .page-top a {
    padding: 1rem 0; }
    .page-top a img {
      margin: 0 auto; }

.contact {
  display: flex;
  justify-content: space-between; }
  .contact .left, .contact .right {
    margin: -.5rem; }

/*
==========================================================
	メディアクエリ
==========================================================
*/
/* 959px以下 */
@media screen and (max-width: 959px) {
  .footer-banner {
    padding: 1rem; }
    .footer-banner .flex-bt {
      flex-wrap: wrap; }
      .footer-banner .flex-bt .item {
        margin-top: 0; }
        .footer-banner .flex-bt .item:last-child p {
          margin-bottom: 0; }
        .footer-banner .flex-bt .item p {
          margin-top: 0; }
  .main-footer {
    padding: 0; }
    .main-footer .flex-cnt {
      flex-wrap: wrap; }
      .main-footer .flex-cnt .flex-item {
        flex-grow: 1;
        margin-right: 0;
        width: 50%; }
      .main-footer .flex-cnt .item3 {
        flex-grow: 3; }
  .footer-main-nav .menu-item .sub-menu .menu-item a {
    font-size: .9rem; }
  .common-footer1 {
    margin-top: 1rem; }
    .common-footer1::after {
      transform: translate(-50%, -50%);
      width: 120%;
      height: 100%;
      background-size: cover; }
    .common-footer1 .cmp-recr {
      padding: 1rem; }
      .common-footer1 .cmp-recr h2 {
        padding: 1rem 1rem;
        font-size: 1.2rem; }
      .common-footer1 .cmp-recr .flex-bt {
        display: block; }
        .common-footer1 .cmp-recr .flex-bt .item-l {
          margin-bottom: 2rem; }
  .common-footer3 {
    position: relative;
    z-index: 1;
    padding: 1rem; }
  .footer-btm {
    padding: 2rem 0 0rem;
    display: block; }
  #menu-footer-nav3 li a {
    font-size: .9rem; }
  .contact {
    display: block; }
    .contact .left, .contact .right {
      margin: -.25rem; }
    .contact .left {
      margin-bottom: 1rem; } }

/*
==========================================================
	ヘッダー
==========================================================
*/
.header-inner {
  max-width: 1200px;
  margin: 0 auto; }

.nav-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.nav-left {
  flex-basis: 320px; }

.nav-right {
  flex-basis: calc(100% - 320px); }
  .nav-right nav {
    float: right; }

.main-nav {
  display: flex; }
  .main-nav li {
    margin-right: 1rem;
    letter-spacing: 1px;
    text-align: center; }
    .main-nav li:nth-child(1) a {
      text-align: center; }
      .main-nav li:nth-child(1) a::after {
        content: "COMPANY"; }
    .main-nav li:nth-child(2) a::after {
      content: "SERVICE"; }
    .main-nav li:nth-child(3) a::after {
      content: "RECRUIT"; }
    .main-nav li:nth-child(4) a::after {
      content: "ACCESS"; }
    .main-nav li:last-child {
      margin-right: 0; }
    .main-nav li a {
      position: relative;
      padding: 1.5rem 0; }
      .main-nav li a.current::before {
        opacity: 1;
        bottom: 0; }
      .main-nav li a::before {
        content: "";
        position: absolute;
        bottom: 1rem;
        opacity: 0;
        transition: all .6s;
        width: 100%;
        height: 4px;
        background: #CC0000; }
      .main-nav li a::after {
        position: relative;
        top: -2px;
        display: block;
        font-size: .5rem;
        font-weight: normal;
        color: #CC0000; }
      .main-nav li a:hover::before {
        opacity: 1;
        bottom: 0; }
  .main-nav .sub-menu {
    display: none; }

.navigation {
  cursor: pointer;
  z-index: 9999999;
  display: flex;
  align-items: center;
  color: #CC0000;
  position: absolute;
  top: 0;
  right: 0; }

.close .line1 {
  width: 140%;
  top: 48%;
  left: -18%;
  transform: rotate(-45deg);
  transition: all .4s; }

.close .line3 {
  opacity: 0; }

.nav-btn {
  height: 2rem;
  width: 2rem;
  position: relative; }

.line1 {
  display: block;
  height: 3px;
  width: 70%;
  background: #141414;
  position: absolute;
  top: 27%;
  left: 40%;
  transform: rotate(45deg);
  transition: all .4s; }

.line2 {
  display: block;
  height: 3px;
  width: 140%;
  background: #141414;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  transition: all .4s; }

.line3 {
  display: block;
  height: 3px;
  width: 70%;
  background: #141414;
  position: absolute;
  bottom: 21%;
  left: -6%;
  transform: rotate(45deg);
  transition: all .4s; }

/*
==========================================================
	メディアクエリ
==========================================================
*/
/* ~959px */
@media screen and (max-width: 959px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999;
    background-color: #fff; }
  .header-inner {
    padding: 1rem; }
  .nav-left {
    flex-basis: 240px;
    transition: all .6s; }
    .nav-left.size-down {
      flex-basis: 160px; }
  .nav-wrap {
    position: relative;
    z-index: 1; }
  .nav-mb-cnt {
    position: fixed;
    z-index: 999999;
    width: 100%;
    height: 100%;
    left: 0;
    transition: all .6s;
    bottom: -100vh;
    background-color: #fff; }
    .nav-mb-cnt::before {
      content: "";
      width: 100%;
      height: 50%;
      background-color: #eee;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      transition: all .6s;
      transition-delay: .6s;
      width: 0; }
    .nav-mb-cnt::after {
      content: "";
      width: 100%;
      height: 50%;
      background-color: #eee;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
      transition: all .6s;
      transition-delay: .6s;
      width: 0; }
    .nav-mb-cnt .inner {
      width: 100%;
      height: 100%;
      background: #fff;
      transition: all 1.8s;
      transition-delay: .6s;
      position: absolute;
      bottom: -100vh; }
      .nav-mb-cnt .inner nav {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99999; }
        .nav-mb-cnt .inner nav ul li {
          text-align: center; }
          .nav-mb-cnt .inner nav ul li a {
            font-size: 1.2rem;
            padding: 1rem;
            border-bottom: 1px solid #fff;
            letter-spacing: 2%; }
    .nav-mb-cnt.open {
      bottom: 0; }
      .nav-mb-cnt.open::before, .nav-mb-cnt.open::after {
        width: 100%; }
      .nav-mb-cnt.open .inner {
        bottom: 0; } }

/* 600px~959px */
/* ~600px */
.flex-bt {
  display: flex;
  justify-content: space-between; }
  .flex-bt .item {
    flex-basis: 48%; }

@media screen and (max-width: 959px) {
  .flex-bt {
    display: block; }
    .flex-bt .item {
      margin-bottom: 1rem; }
      .flex-bt .item:last-child {
        margin-bottom: 0; } }
