.common-footer1 {
	margin-top: 4rem;
	background: $red;
	position: relative;
	overflow: hidden;
	&::after {
		content: "";
		position: absolute;
		z-index: 1;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%) scale(1.03);
		width: 745px;
		height: 700px;
		background: url(../images/footer/icon-lotas.png);
	}
}
.cmp-recr {
	padding: 7rem 0;
	position: relative;
	z-index: 3;
	.img {
		margin: 0;
	}
	h2 {
		background-color: #fff;
		margin: 0;
		padding: 3rem 1rem;
		font-size: 1.15rem;
		text-align: center;
		line-height: 1.4;
		font-weight: normal;
	}
	.item-l {
		.title {
			&::after {
				content: "COMPANY";
				font-size: .7em;
				color: $red;
			}
		}
	}
	.item-r {
		.title {
			&::after {
				content: "RECRUIT";
				font-size: .7em;
				color: $red;
			}
		}
	}
}

.common-footer2 {
	background-color: $pink;
}
.footer-banner {
	padding: 2rem 0rem;
}

.common-footer3 {
	background-color: $black_footer;
	color: #fff;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: -1;
	width: 100%;
}
#menu-footer-nav3 {
	li {
		display: inline-block;
		margin-bottom: 0;
		margin-right: 1.5rem;
		&:last-child {
			margin-right: 0;
		}
	}
}
.footer-btm {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: 1px solid #707070;
	padding: 2rem 0 1rem;
}
.copyright {
	margin: 0;
}
.main-footer {
	padding-top: 3rem;
	.flex-cnt {
		display: flex;
		margin-bottom: 1rem;
		p {
			margin: 0;
		}
		.flex-item {
			margin-right: 5%;
			&:last-child {
				margin-right: 0;
			}
		}
		.item1 {
			width: 15%;
		}
		.item2 {
			width: 30%;
		}
		.item3 {
			width: 45%;
		}
	}
}

.footer-main-nav {
	& > .menu-item {
		margin-bottom: 1rem;
	}
	.menu-item {
		a {
			color: #fff;
		}
		.sub-menu {
			padding-left: 2rem;
			list-style: circle;
			margin: .5rem auto;
			.menu-item {
				a {
					color: $gray;
				}
			}
		}
	}
}

.page-top {
	background-color: $red;
	margin: 0;
	text-align: center;
	a {
		padding: 1rem 0;
		img {
			margin: 0 auto;
		}
	}
}
.contact {
	display: flex;
	justify-content: space-between;
	.left,.right {
		margin: -.5rem;
	}
}

/*
==========================================================
	メディアクエリ
==========================================================
*/
/* 959px以下 */
@include mq() {

	.footer-banner {
		padding: 1rem;
		.flex-bt {
			flex-wrap: wrap;
			.item {
				margin-top: 0;
				&:last-child {
					p {
						margin-bottom: 0;
					}
				}
				p {
					margin-top: 0;
				}
			}
		}
	}
	.main-footer {
		padding: 0;
		.flex-cnt {
			flex-wrap: wrap;
			.flex-item {
				flex-grow: 1;
				margin-right: 0;
				width: 50%;
			}
			.item3 {
				flex-grow: 3;
			}
		}
	}
	.footer-main-nav {
		.menu-item {
			.sub-menu {
				.menu-item {
					a {
						font-size: .9rem;
					}
				}
			}
		}
	}
	.common-footer1 {
		margin-top: 1rem;
		&::after {
			transform: translate(-50%, -50%);
			width: 120%;
			height: 100%;
			background-size: cover;
		}
		.cmp-recr {
			padding: 1rem;
			h2 {
				padding: 1rem 1rem;
				font-size: 1.2rem;
			}
			.flex-bt {
				display: block;
				.item-l {
					margin-bottom: 2rem;
				}
			}
		}
	}
	.common-footer3 {
		position: relative;
		z-index: 1;
		padding: 1rem;
	}
	.footer-btm {
		padding: 2rem 0 0rem;
		display: block;
	}
	#menu-footer-nav3 {
		li {
			a {
				font-size: .9rem;
			}
		}
	}
	.contact {
		display: block;
		.left,.right {
			margin: -.25rem;
		}
		.left {
			margin-bottom: 1rem;
		}
	}

}