/*
==========================================================
	ヘッダー
==========================================================
*/
.header-inner {
	max-width: 1200px;
	margin: 0 auto;
}

.nav-wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.nav-left {
	flex-basis: 320px;
}
.nav-right {
	flex-basis: calc(100% - 320px);
	nav {
		float: right;
	}
}

.main-nav {
	display: flex;
	li {
		margin-right: 1rem;
		letter-spacing: 1px;
		text-align: center;
		&:nth-child(1) {
			a {
				text-align: center;
				&::after {
					content: "COMPANY";
				}
			}
		}
		&:nth-child(2) {
			a {
				&::after {
					content: "SERVICE";
				}
			}
		}
		&:nth-child(3) {
			a {
				&::after {
					content: "RECRUIT";
				}
			}
		}
		&:nth-child(4) {
			a {
				&::after {
					content: "ACCESS";
				}
			}
		}
		&:last-child {
			margin-right: 0;
		}
		a {
			position: relative;
			padding: 1.5rem 0;;
			&.current {
				&::before {
					opacity: 1;
					bottom: 0;
				}
			}
			&::before {
				content: "";
				position: absolute;
				bottom: 1rem;
				opacity: 0;
				transition: all .6s;
				width: 100%;
				height: 4px;
				background: $red;
			}
			&::after {
				position: relative;
				top: -2px;
				display: block;
				font-size: .5rem;
				font-weight: normal;
				color: $red;
			}
			&:hover {
				&::before {
					opacity: 1;
					bottom: 0;
				}
			}
		}
	}
	.sub-menu {
		display: none;
	}
}
.navigation {
	cursor: pointer;
	z-index: 9999999;
	display: flex;
	align-items: center;
	color: $red;
	position: absolute;
	top: 0;
	right: 0;
}
.close {
	.line1 {
		width: 140%;
		top: 48%;
		left: -18%;
		transform: rotate(-45deg);
		transition: all .4s;
	}
	.line2 {
		
	}
	.line3 {
		opacity: 0;
	}
}
.nav-btn {
	height: 2rem;
	width: 2rem;
	position: relative;
}
.line1 {
	display: block;
	height: 3px;
	width: 70%;
	background: $black;
	position: absolute;
	top: 27%;
	left: 40%;
	transform: rotate(45deg);
	transition: all .4s;
}
.line2 {
	display: block;
	height: 3px;
	width: 140%;
	background: $black;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%) rotate(45deg);
	transition: all .4s;
}
.line3 {
	display: block;
	height: 3px;
	width: 70%;
	background: $black;
	position: absolute;
	bottom: 21%;
	left: -6%;
	transform: rotate(45deg);
	transition: all .4s;
}
/*
==========================================================
	メディアクエリ
==========================================================
*/

/* ~959px */
@include mq {
	.header {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 999999;
		background-color: #fff;
	}
	.header-inner {
		padding: 1rem;
	}
	.nav-left {
		flex-basis: 240px;
		transition: all .6s;
		&.size-down {
			flex-basis: 160px;
		}
	}
	.nav-wrap {
		position: relative;
		z-index: 1;
	}
	.nav-mb-cnt {
		position: fixed;
		z-index: 999999;
		width: 100%;
		height: 100%;
		left: 0;
		transition: all .6s;
		bottom: -100vh;
		background-color: #fff;

		&::before {
			content: "";
			width: 100%;
			height: 50%;
			background-color: #eee;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			transition: all .6s;
			transition-delay: .6s;
			width: 0;
		}
		&::after {
			content: "";
			width: 100%;
			height: 50%;
			background-color: #eee;
			position: absolute;
			bottom: 0;
			right: 0;
			z-index: 1;
			transition: all .6s;
			transition-delay: .6s;
			width: 0;
		}
		.inner {
			width: 100%;
			height: 100%;
			background: #fff;
			transition: all 1.8s;
			transition-delay: .6s;
			position: absolute;
			bottom: -100vh;
			nav {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				z-index: 99999;
				ul {
					li {
						text-align: center;
						a {
							font-size: 1.2rem;
							padding: 1rem;
							border-bottom: 1px solid #fff;
							letter-spacing: 2%;

						}
					}
				}
			}
		}
		&.open {
			bottom: 0;
			&::before,&::after {
				width: 100%;
			}
			.inner {
				bottom: 0;
			}
		}
	}
}

/* 600px~959px */
@include mq(medium) {
}
/* ~600px */
@include mq(small) {
}