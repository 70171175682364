//==========================================================
//	reset
//==========================================================

//==========================================================
//	link
//==========================================================


//==========================================================
//	button
//==========================================================

//==========================================================
//	form
//==========================================================

//==========================================================
//	keyframe
//==========================================================

//==========================================================
//	position
//==========================================================
// position
.centering {
	position: absolute;
	z-index: 10;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	text-align: center;
	&Y {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
	&X {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}
	&_fix {
		@extend .centering;
		position: fixed;
	}
}

// fixed center
.centering_fix {
	position: fixed;
	z-index: 9999;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}

// align
.reverse {
	display: flex;
	flex-direction: row-reverse;
}